import React from 'react'
import { NavLink } from 'react-router-dom'
import './Footer.css'

const Footer = () => {
  return (
    <div className='footer'>
    <div className='footerBox'>
        <NavLink to="/cookies" className={({ isActive }) => (isActive ? "active" : "desactive")}>
        <div className='footerText'>Cookies & Aviso Legal</div>
        </NavLink>
    <a href="https://www.instagram.com/javierpardo.art/?hl=es" target="_blank" rel="noopener noreferrer"> <img src={process.env.PUBLIC_URL + '/img/instagram.png'} alt="Instagram" /></a>
    <a href="mailto:info@javierpardoarte.com" target="_blank" rel="noopener noreferrer"> <img src={process.env.PUBLIC_URL + '/img/email.png'} alt="email" /></a>
</div>
</div>
  )
}

export default Footer