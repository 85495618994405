import React from 'react'
import { NavLink } from 'react-router-dom'
import Footer from '../Pages/Footer'
import NavbarGrabados from './NavbarGrabados'

const bar = (props) => {
  return (
    <div className={`nav-elements  ${props.showNavbar && 'active'}`} onClick={props.handleShowNavbar}>
                    <ul className='ulList'>
                        <NavLink to="/grabados" className={({ isActive }) => (isActive ? "active" : "desactive")}>
                            <li>
                                Grabados
                            </li>
                            <NavbarGrabados />
                        </NavLink>
                        <NavLink to="/acuarelas" className={({ isActive }) => (isActive ? "active" : "desactive")}>
                            <li>Acuarelas</li>
                        </NavLink>
                        <NavLink to="/esmaltes" className={({ isActive }) => (isActive ? "active" : "desactive")}>
                            <li>Esmaltes</li>
                        </NavLink>
                        <NavLink to="/about" className={({ isActive }) => (isActive ? "active" : "desactive")}>
                            <li>Sobre mí</li>
                        </NavLink>
                    </ul>
                    <Footer />
                </div>
  )
}

export default bar