import React from 'react'
import "./Error404.css"

const Error404 = () => {
  return (
    <div className="error">
    < img  
       src={process.env.PUBLIC_URL+"/img/error.jpg"} 
       alt={"Imagen error"}
       />
    <div className='errorBlock'>
        <div className="errorTitle"> Error 404 </div>
        <div className="errorText"> Vaya parece que algo ha ido mal. Vamos a intentar repararlo lo antes posible pero mientras si queres pueder volver al inicio y ver otras secciones.</div>
        <div className="errorLink"><a href="https://javierpardoarte.com" target="_blank" rel="noopener noreferrer"> Volver al inicio </a></div>
    </div>
     </div>
  )
}

export default Error404