import React from 'react'
import './Details.css'

const Details = (props) => {
    var papel = props.papel
    var matriz = props.matriz
    return (
        <div className='detailContainer'>
            <button className='detailButton' onClick={props.showDetails}>
            <img src={process.env.PUBLIC_URL + '/img/close_black.png'} alt="Close" className='closeBlack' />
            </button>
            <div className="detailInfo">
            <div className='detailsTitle'> {props.title}</div>
            {papel
            ? <div className='detailsPapel'>Papel: {props.papel}</div>
            : null}
            {matriz
            ?<div className='detaislmatriz'>Matriz: {props.matriz}</div>
            : null}
            <div className='detailsOtros1'>{props.otros1}</div>
            <div className='detailsOtros2'>{props.otros2}</div>
            <div className='detailsEsmalte'>{props.esmalte}</div>
            </div>
        </div>
    )
}

export default Details