import React from 'react'
import './Cookies.css'

const Cookies = () => {
    return (
        <div className='cookiesText'>
            <div className='cookiesPart'>
                <h1>POLÍTICA DE COOKIES Y AVISO LEGAL</h1>
                <h2>1. POLÍTICA DE COOKIES</h2>
                <p>El objetivo de esta política es informar a los interesados acerca de las cookies que emplea esta página
                    web de conformidad con lo establecido en la Ley 34/2002, de 11 de julio, de Servicios de la Sociedad de
                    la Información y de Comercio Electrónico, y el Reglamento (UE) 2016/679 del Parlamento Europeo y del
                    Consejo de 27 de abril de 2016.</p>
                <h3>¿QUÉ SON LAS COOKIES?</h3>
                <p>Las cookies son ficheros que se descargan en su Ordenador, Smartphone o Tablet al acceder a determinadas
                    páginas web. La utilización de cookies, ofrece numerosas ventajas en la prestación de servicios de la
                    Sociedad de la Información, puesto que, entre otras: </p>
                <ul>
                    <li>Facilita la navegación del usuario en el Sitio Web.</li>
                    <li>Facilita al usuario el acceso a los diferentes servicios que ofrece el Sitio Web.</li>
                    <li>Evita al usuario volver a configurar características generales predefinidas cada vez que accede al
                        Sitio Web.</li>
                    <li>Favorece la mejora del funcionamiento y de los servicios prestados a través del Sitio Web, tras el
                        correspondiente análisis de la información obtenida a través de las cookies instaladas.</li>
                    <li>Permiten a un Sitio Web, entre otras cosas, almacenar y recuperar información sobre los hábitos de
                        navegación de un usuario o de su equipo y, dependiendo de la información que contengan y de la forma
                        en que utilice su equipo, pueden utilizarse para reconocer al usuario.</li>
                </ul>
                <p>La mayoría de los navegadores aceptan como estándar a las cookies y, con independencia de las mismas,
                    permiten o impiden en los ajustes de seguridad las cookies temporales o memorizadas.</p>
                <p>Ten en cuenta que para poder utilizar y contar con una mejor experiencia de navegación, es necesario que
                    tengas habilitadas las cookies, especialmente aquellas de carácter técnico que resultan necesarias para
                    que pueda identificarte como usuario registrado cada vez que accedas a la presente web.</p>
                <p>En caso de no querer recibir cookies, por favor, configure su navegador de internet, para que las borre
                    del disco duro de su ordenador, las bloquee o le avise en su caso de instalación de las mismas.</p>
                <h3>TIPOS DE COOKIES</h3>
                <h3>SEGÚN LA FINALIDAD</h3>
                <ul>
                    <li><b>Cookies técnicas y funcionales</b> son aquellas que permiten al usuario la navegación a través de
                        una página web, plataforma o aplicación y la utilización de las diferentes opciones o servicios que
                        en ella existan.</li>
                    <li><b>Cookies analíticas</b> son aquellas que permiten al responsable de las mismas el seguimiento y
                        análisis del comportamiento de los usuarios de los sitios web a los que están vinculadas. La
                        información recogida mediante este tipo de cookies se utiliza en la medición de la actividad de los
                        sitios web, aplicación o plataforma y para la elaboración de perfiles de navegación de los usuarios
                        de dichos sitios, aplicaciones y plataformas, con el fin de introducir mejoras en función del
                        análisis de los datos de uso que hacen los usuarios del servicio.</li>
                    <li><b>Cookies publicitarias</b> son aquellas que permiten la gestión, de la forma más eficaz posible,
                        de los espacios publicitarios que, en su caso, el editor haya incluido en una página web, aplicación
                        o plataforma desde la que presta el servicio solicitado en base a criterios como el contenido
                        editado o la frecuencia en la que se muestran los anuncios.</li>
                    <li><b>Cookies de publicidad comportamental</b> recogen información sobre las preferencias y elecciones
                        personales del usuario (retargeting) para permitir la gestión, de la forma más eficaz posible, de
                        los espacios publicitarios que, en su caso, el editor haya incluido en una página web, aplicación o
                        plataforma desde la que presta el servicio solicitado.</li>
                    <li><b>Cookies sociales</b>: son establecidas por las plataformas de redes sociales en los servicios
                        para permitirle compartir contenido con sus amigos y redes. Las plataformas de medios sociales
                        tienen la capacidad de rastrear su actividad en línea fuera de los Servicios. Esto puede afectar al
                        contenido y los mensajes que ve en otros servicios que visita.</li>
                    <li><b>Cookies de afiliados</b> permiten hacer un seguimiento de las visitas procedentes de otras webs,
                        con las que el sitio web establece un contrato de afiliación (empresas de afiliación).</li>
                    <li><b>Cookies de seguridad</b> almacenan información cifrada para evitar que los datos guardados en
                        ellas sean vulnerables a ataques maliciosos de terceros.</li>
                </ul>
                <h3>SEGÚN LA PROPIEDAD</h3>
                <ul>
                    <li><b>Cookies propias</b>: son aquellas que se envían al equipo terminal del usuario desde un equipo o
                        dominio gestionado por el propio editor y desde el que se presta el servicio solicitado por el
                        usuario.</li>
                    <li><b>Cookies de terceros</b>: son aquellas que se envían al equipo terminal del usuario desde un
                        equipo o dominio que no es gestionado por el editor, sino por otra entidad que trata los datos
                        obtenidos través de las cookies.</li>
                </ul>
                <h3>SEGÚN EL PLAZO DE CONSERVACIÓN</h3>
                <ul>
                    <li><b>Cookies de sesión</b> son un tipo de cookies diseñadas para recabar y almacenar datos mientras el
                        usuario accede a una página web.</li>
                    <li><b>Cookies persistentes</b> son un tipo de cookies en el que los datos siguen almacenados en el
                        terminal y pueden ser accedidos y tratados durante un período definido por el responsable de la
                        cookie, y que puede ir de unos minutos a varios años.</li>
                </ul>
                <h3>¿QUÉ TIPOS DE COOKIES UTILIZA ESTA PÁGINA WEB?</h3>
                <p>Siguiendo las directrices de la Agencia Española de Protección de Datos, procedemos a detallar el uso de
                    las cookies que esta página web emplea, con el fin de proporcionarle la máxima información posible.</p>
                <h3>Cookies de Terceros:</h3>
                <p>Son aquellas que se envían al equipo terminal del usuario desde un equipo o dominio que no es gestionado
                    por el editor, sino por otra entidad que trata los datos obtenidos través de las cookies.</p>
                <p>A continuación la lista de las que utilizamos:</p>
                <table className="tableCookies">
                    <tbody><tr>
                        <th>Nombre</th>
                        <th>Tipo</th>
                        <th>Proveedor</th>
                        <th>Finalidad</th>
                        <th>Duración</th>
                        <th>Más info</th>
                    </tr>
                        <tr>
                            <td>_ga</td>
                            <td>Analítica</td>
                            <td>Google Analytics</td>
                            <td>Habilita la función de control de visitas únicas. La primera vez que un usuario entre en el
                                sitio web a través de un navegador se instalará esta cookie, Cuando este uusario vuelta a entrar
                                en la web con el mimsmo navegador, la cookie considerará que es el mimso navegador, la cookie
                                considerará que es el mismo usuario. Solo en el caso de que el usuario cambie de navegador, se
                                considerará otro usuario.</td>
                            <td>2 años</td>
                            <td>Persitente</td>
                        </tr>
                        <tr>
                            <td>_gat</td>
                            <td>Analítica</td>
                            <td>Google Analytics</td>
                            <td>Se utiliza para limitar la velocidad de petición - la limitación de la recogida de datos en los
                                sitios de alto tráfico.</td>
                            <td>10 minutos</td>
                            <td>Sesión</td>
                        </tr>
                        <tr>
                            <td>_gid</td>
                            <td>Analítica</td>
                            <td>Google Analytics</td>
                            <td>Se utiliza para distinguir a los usuarios.</td>
                            <td>24 horas</td>
                            <td>Sesión</td>
                        </tr>
                    </tbody></table>
                <p>JAVIER PARDO ARTE no se hace responsable, en ningún caso ni del contenido ni de la veracidad de
                    las políticas y/o condiciones de uso y privacidad de los terceros, incluidos, a través de los enlaces,
                    en esta política.</p>
                <p>Obtener más información para deshabilitar las cookies de Google Analytics:</p>
                <ul>
                    <li><a href="https://policies.google.com/technologies/managing" target="_blank" rel="noopener noreferrer" >Política de privacidad
                        de Google</a></li>
                    <li><a href="https://support.google.com/analytics/answer/181881?hl=es" target="_blank" rel="noopener noreferrer" >Exclusión de
                        Google Analytic</a></li>
                </ul>
                <h3>CONSENTIMIENTO </h3>
                <p>En relación con la utilización de cookies de este sitio web, el usuario autoriza y consiente su uso de la
                    siguiente forma: Al hacer click en aceptar, usted acepta el uso de nuestras
                    cookies y en su caso las de los terceros. El panel de configuración/privacidad le permite la posibilidad
                    de aceptarlas por tipología.</p>

                <h3>PLAZOS O CRITERIOS DE CONSERVACIÓN DE LOS DATOS</h3>
                <p>Los datos personales proporcionados se conservarán durante el tiempo necesario para cumplir con las
                    finalidades para los que fueron recopilados inicialmente.</p>
                <p>Una vez que los datos dejen de ser necesarios para el tratamiento en cuestión, estos se mantendrán
                    debidamente bloqueados para, en su caso, ponerlos a disposición de las Administraciones y Organismos
                    Públicas competentes, Jueces y Tribunales o el Ministerio Fiscal, durante el plazo de prescripción de
                    las acciones que pudieran derivarse de la relación mantenida con el cliente y/o los plazos de
                    conservación previstos legalmente.</p>
                <p>Periodo de bloqueo de los datos: </p>
                <ul>
                    <li><b>EL CÓDIGO CIVIL.</b> Entre 5 o 15 años según el caso, atendiendo a lo dispuesto en el artículo
                        1964.2 del cuerpo legal mencionado.</li>
                    <li><b>EL CÓDIGO DEL COMERCIO.</b> Durante 6 años, atendiendo a dispuesto en el artículo 30 del cuerpo
                        legal mencionado. Se aplica respecto de la información mercantil relacionada con (facturas emitidas
                        y recibidas, tickets, facturas rectificativas, documentos bancarios, etc.).</li>
                    <li><b>LA LEY GENERAL TRIBUTARIA.</b> Durante 4 años atendiendo a dispuesto en los artículos 66 a 70 del
                        cuerpo legal mencionado. Se aplica a la información relacionada con las obligaciones fiscales.</li>
                    <li><b>LEY 10/2010, DE 28 DE ABRIL, DE PREVENCIÓN DEL BLANQUEO DE CAPITALES Y DE LA FINANCIACIÓN DEL
                        TERRORISMO.</b> Durante 10 años atendiendo a lo dispuesto en 25 de la ley.</li>
                </ul>

                <h3>CÓMO BLOQUEAR REVOCAR O ELIMINAR LAS COOKIES INSTALADAS</h3>
                <p>Puede usted permitir, bloquear, revocar o eliminar las cookies instaladas en su equipo mediante la
                    configuración de las opciones de su navegador. Puede encontrar información sobre cómo hacerlo, en
                    relación con los navegadores más comunes en los links que se incluyen a continuación:</p>
                <ul>
                    <li><a href="https://support.microsoft.com/es-es/kb/278835" target="_blank" rel="noopener noreferrer" >Explorer</a></li>
                    <li><a href="https://privacy.microsoft.com/es-es/windows-10-microsoft-edge-and-privacy" target="_blank" rel="noopener noreferrer" >Microsoft Edge</a></li>
                    <li><a href="https://support.google.com/chrome/answer/95647?co=GENIE.Platform%3DDesktop&amp;hl=esttp://support.google.com/chrome/bin/answer.py?hl=es&amp;answer=95647" target="_blank" rel="noopener noreferrer" >Chrome</a></li>
                    <li><a href="https://support.mozilla.org/es/kb/Borrar%20cookies" target="_blank" rel="noopener noreferrer" >Firefox</a></li>
                    <li><a href="https://support.apple.com/kb/ph5042" target="_blank" rel="noopener noreferrer" >Safari</a></li>
                    <li><a href="https://help.opera.com/en/latest/web-preferences/#cookies " target="_blank" rel="noopener noreferrer" >Opera</a></li>
                </ul>
                <p>Le informamos, no obstante, de la posibilidad de que la desactivación de alguna cookie impida o dificulte
                    la navegación o la prestación de los servicios ofrecidos en la página web.</p>

                <h3>DESTINATARIOS DE LA INFORMACIÓN</h3>
                <p>Tendrán acceso a la información obtenida a través de las cookies del sitio web las empresas colaboradoras
                    detalladas en el Panel de Configuración, donde podrás asimismo consultar las finalidades con las que la
                    utilizan y sus respectivas políticas de privacidad.</p>
                <p>Asimismo, te informamos de que algunos de nuestros proveedores así como empresas colaboradoras pueden
                    realizar transferencias a terceros países. Puedes informarte de las transferencias a terceros países
                    que, en su caso, realizan en sus correspondientes políticas de privacidad, accesibles a través de los
                    enlaces facilitados en el Panel de Configuración.</p>
                <h3>MODIFICACIONES / ACTUALIZACIÓN </h3>
                <p>La presente política de cookies puede verse modificada/actualizada en función de las exigencias legales
                    establecidas o con la finalidad de adaptar dicha política a las instrucciones dictadas por la Agencia
                    Española de Protección de Datos o cambios en nuestro sitio web. </p>
                <p>Por esta razón, aconsejamos a los usuarios que visiten periódicamente nuestra política de cookies.</p>
                <p>Si tiene dudas acerca de esta política de cookies, puede contactar con JAVIER PARDO ARTE a
                    través del siguiente correo electrónico: <a href="mailto:info@javierpardoarte.com" target="_blank" rel="noopener noreferrer">info@javierpardoarte.com</a></p>
                <p>Puede leer a continuación para obtener más información sobre el tratamiento de sus datos personales mediante el
                    uso de cookies. </p>

            </div>
            <div className="avisoLegal">
                <h2>2. AVISO LEGAL</h2>
                <p>De conformidad con lo establecido en la Ley 34/2002, de 11 de julio, de servicios de la sociedad de la información y de comercio electrónico, se facilita la siguiente información: </p>
                <h3>DATOS IDENTIFICATIVOS</h3>
                <p>Usted está visitando la página web  www.javierpardoarte.com  titularidad de JAVIER PARDO ARTE.</p>
                    <h3>USUARIOS</h3>
                    <p>Las presentes condiciones (en adelante Aviso Legal) tiene por finalidad regular el uso de la página web de EL TITULAR que pone a disposición del público.</p>
                    <p>El acceso y/o uso de esta página web atribuye la condición de USUARIO, que acepta, desde dicho acceso y/o uso, las condiciones generales de uso aquí reflejadas. Las citadas condiciones serán de aplicación independientemente de las condiciones generales de contratación que en su caso resulten de obligado cumplimiento.</p>
                    <h3>USO DEL PORTAL</h3>
                    <p>www.javierpardoarte.com proporciona el acceso a multitud de informaciones, servicios, programas o datos (en adelante, “los contenidos”) en Internet pertenecientes a EL TITULAR o a sus licenciantes a los que el USUARIO puede tener acceso.</p>
                    <p>El usuario asume la responsabilidad del uso del portal. Dicha responsabilidad se extiende al registro que fuese necesario para acceder a determinados servicios o contenidos. En dicho registro el USUARIO será responsable de aportar información veraz y lícita. Como consecuencia de este registro, al USUARIO se le puede proporcionar una contraseña de la que será responsable, comprometiéndose a hacer un uso diligente y confidencial de la misma.</p>
                    <p>El USUARIO se compromete a hacer un uso adecuado de los contenidos y servicios (p.e. servicios de chat, foros de discusión o grupos de noticias) que EL TITULAR ofrece a través de su portal y con carácter enunciativo pero no limitativo, a no emplearlos para:</p>
                    <ul>
                        <li>Incurrir en actividades ilícitas, ilegales o contrarias a la buena fe y al orden público. </li>
                        <li>Difundir contenidos o propaganda racista, xenófoba, pornográfico-ilegal, de apología del terrorismo o atentatoria contra los derechos humanos. </li>
                        <li>Provocar daños en los sistemas físicos y lógicos de JAVIER PARDO ARTE, de sus proveedores o de terceras personas, introducir o difundir en l red virus informáticos o cualesquiera otros sistemas físicos o lógicos que sean susceptibles de provocar los daños anteriormente mencionados.</li>
                        <li>Intentar acceder y, en su caso, utilizar las cuentas de correo electrónico de otros usuarios y modificar o manipular sus mensajes.</li>
                        <li>Utilizar el sitio web ni las informaciones que en él se contienen con fines comerciales, políticos, publicitarios y para cualquier uso comercial, sobre todo en el envío de correos electrónicos no solicitados. </li>
                    </ul>
                    <p>EL TITULAR se reserva el derecho a retirar todos aquellos comentarios y aportaciones que vulneren el respeto a la dignidad de la persona, que sean discriminatorios, xenófobos, racistas pornográficos, que atenten contra la juventud o la infancia, el orden o la seguridad pública o que, a su juicio, no resultarán adecuados para su publicación. En cualquier caso, EL TITULAR no será responsable de las opiniones vertidas por los usuarios a través de los foros, chats, u otras herramientas de participación. </p>
                    <h3>PROTECCIÓN DE DATOS</h3>
                    <p>Todo lo relativo a la política de protección de datos se encuentra recogido en el documento de política de privacidad.</p>
                    <h3>CONTENIDOS. PROPIEDAD INTELECTUAL E INDUSTRIAL</h3>
                    <p>EL TITULAR es propietario de todos los derechos de propiedad intelectual e industrial de su página web, así como de los elementos contenidos en la misma (a título enunciativo: imágenes, fotografías, sonido, audio, vídeo, software o textos; marcas o logotipos, combinaciones de colores, estructura y diseño, selección de materiales usados, programas de ordenador necesarios para su funcionamiento, acceso y uso, etc), titularidad del TITULAR o bien de sus licenciantes.</p>
                    <p>Todos los derechos reservados. En virtud de lo dispuesto en los artículos 8 y 32.1, párrafo segundo, de la Ley de Propiedad Intelectual, quedan expresamente prohibidas la reproducción, la distribución y la comunicación pública, incluida su modalidad de puesta a disposición, de la totalidad o parte de los contenidos de esta página web, con fines comerciales, en cualquier soporte y por cualquier medio técnico, sin la autorización del TITULAR.</p>
                    <h3>EXCLUSIÓN DE GARANTÍAS Y RESPONSABILIDAD</h3>
                    <p>EL USUARIO reconoce que la utilización de la página web y de sus contenidos y servicios se desarrolla bajo su exclusiva responsabilidad. En concreto, a título meramente enunciativo, EL TITULAR no asume ninguna responsabilidad en los siguientes ámbitos:</p>
                    <ul>
                        <li>La disponibilidad del funcionamiento de la página web, sus servicios y contenidos y su calidad o interoperabilidad.</li>
                        <li>La finalidad para la que la página web sirva a los objetivos del USUARIO.</li>
                        <li>La infracción de la legislación vigente por parte del USUARIO o terceros y, en concreto, de los derechos de propiedad intelectual e industrial que sean titularidad de otras personas o entidades.</li>
                        <li>La existencia de códigos maliciosos o cualquier otro elemento informático dañino que pudiera causar el sistema informático del USUARIO o de terceros. Corresponde al USUARIO, en todo caso, disponer de herramientas adecuadas para la detección y desinfección de estos elementos.</li>
                        <li>El acceso fraudulento a los contenidos o servicios por terceos no autorizados, o, en su caso, la captura, eliminación, alteración, modificación o manipulación de los mensajes y comunicaciones de cualquier clase que dichos terceros pudiera realizar.</li>
                        <li>La exactitud, veracidad, actualidad y utilidad de los contenidos y servicios ofrecidos y la utilización posterior que de ellos haga el USUARIO. EL TITULAR empleará todos los esfuerzos y medios razonables para facilitar la información actualizada y fehaciente.</li>
                        <li>Los daños producidos a equipos informáticos durante el acceso a la página web y los daños producidos a los USUARIOS cuando tengan su origen en fallos o desconexiones en las redes de telecomunicaciones que interrumpan el servicio.</li>
                        <li>Los daños o perjuicios que se deriven de circunstancias acaecidas por caso fortuito o fuerza mayor.</li>
                    </ul>
                    <p>En caso de que existan foros, el uso de los mismos u otros espacios análogos, ha de tenerse en cuenta que los mensajes reflejen únicamente la opinión del USUARIO que los remite, que es el único responsable. EL TITULAR no se hace responsable del contenido de los mensajes enviados por el USUARIO. </p>
                    <h3>MODIFICACIÓN DE ESTE AVISO LEGAL Y DURACIÓN</h3>
                    <p>EL TITULAR se reserva el derecho de efectuar sin previo aviso las modificaciones que considere oportunas en su portal, pudiendo cambiar, suprimir o añadir tantos contenidos y servicios que se presten a través de la misma, como la forma en la que éstos aparezcan representados o localizados en su portal.</p>
                    <p>La vigencia de las citadas condiciones irá en función de su exposición y estarán vigentes hasta que sean modificadas por otras debidamente publicadas.</p>
                    <h3>ENLACES</h3>
                    <p>En el caso de que en www.javierpardoarte.com se incluyesen enlaces o hipervínculos hacia otros sitios de Internet, EL TITULAR no ejercerá ningún tipo de control sobre dichos sitios y contenidos. En ningún caso EL TITULAR asumirá responsabilidad alguna por los contenidos de algún enlace perteneciente a un sitio web ajeno, ni garantizará la disponibilidad técnica, calidad, fiabilidad, exactitud, amplitud, veracidad, validez y constitucionalidad de cualquier materia o información contenida en ninguno de dichos hipervínculos y otros sitios en Internet. </p>
                    <p>Igualmente, la inclusión de estas conexiones externas no implicará ningún tipo de asociación, fusión o participación con las entidades conectadas.</p>
                    <h3>DERECHOS DE EXCLUSIÓN</h3>
                    <p>EL TITULAR ser reserva el derecho a denegar o retirar el acceso a portal y/o los servicios ofrecidos sin necesidad de advertencia previa, a instancia propia o de un tercero, a aquellos usuarios que incumplan el contenido de este aviso legal.</p>
                    <h3>GENERALIDADES</h3>
                    <p>EL TITULAR perseguirá el incumplimiento de las presentes condiciones así como cualquier utilización indebida de su portal ejerciendo todas las acciones civiles y penales que le puedan corresponder en derecho.</p>
                    <h3>LEGISLACIÓN APLICABLE Y JURISDICCIÓN</h3>
                    <p>La relación entre EL TITULAR y EL USUARIO se regirá por la normativa española vigente. Todas las disputas y reclamaciones derivadas de este aviso legal se resolverán por los juzgados y tribunales españoles de Madrid.</p>
                    <h3>MENORES DE EDAD</h3>
                    <p>www.javierpardoarte.com dirige sus servicios a usuarios mayores de 18 años. Los menores de esta edad no están autorizados a utilizar nuestros servicios y no deberán, por tanto, enviarnos sus datos personales. Informamos de que si se da tal circunstancia,  JAVIER PARDO ARTE no se hace responsable de las posibles consecuencias que pudieran derivarse del incumplimiento del aviso que en esta misma cláusula se establece. </p>
                    <h3>AVISO LEGAL DE GOOGLE ANALYTICS</h3>
                    <p>Esta página web utiliza Google Analytics, un servicio analítico de web prestado por Google, Inc., una compañía de Delaware cuya oficina principal esta en 1600 Amphitheatre Parkway, Mountain View (California), CA 94043, Estados Unidos (“Google”).</p>
                    <p>Google Analytics utiliza cookies, que son archivos de texto ubicados en su ordenador, para ayudar al sitio web a analizar el uso que hacen los usuarios del sitio web. La información que genera la cookie acerca de su uso del sitio web (incluyendo su dirección IP) será directamente transmitida y archivada por Google en los servidores de Estados Unidos. Google usará esta información por cuenta nuestra con el propósito de seguir la pista de su uso del sitio web, recopilando informes de la actividad del sitio web y prestando otros servicios relacionados con la actividad del sitio web y el uso de Internet. Google podrá transmitir dicha información a terceros cuando así se lo requiera la legislación, o cuando dichos terceros procesen la información por cuenta de Google. Google no asociará su dirección IP con ningún otro dato del que disponga Google.</p>
                    <p>Puede usted rechazar el tratamiento de los datos o la información rechazando el uso de cookies mediante la selección de la configuración apropiada de su navegador, sin embargo, debe usted saber que si lo hace puede ser que no pueda usar la plena funcionabilidad de este sitio web. Al utilizar este sitio web Usted consiente el tratamiento de información acerca de usted por Google en la forma y para los fines arriba indicados.</p>
                    <h3>MEDIDAS DE SEGURIDAD.- SSL</h3>
                    <p>El Titular ha contratado para su sitio web un certificado SSL («Secure Sockets Layer»).</p>
                    <p>Un certificado SSL permite proteger toda la información personal y confidencial que se pueda manejar en un sitio web, independientemente de la información que se esté transmitiendo, como por ejemplo, desde cualquiera de los formularios de contacto del sitio web hasta el servidor, o los datos introducidos para la suscripción de boletines de noticias o accesos a las áreas protegidas, etc.</p>
                    <p>La dirección del sitio web aparecerá en color verde, activándose el protocolo “https” que permite conexiones seguras desde un servidor web al navegador del usuario.</p>
            </div>
        </div>
    )
}

export default Cookies